.MuiButton-label {
    color: #6441a5;
}

.table-title, .table-description, .libary-title, .libary-description{
    text-align: center;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding-right: 5%;
    padding-left: 5%;
}

.MuiButton-iconSizeMedium > *:first-child {
    color: #ffffff;
}

.MuiSvgIcon-root {
    color: #6441a5;
}

.table-title{
    font-size: 34px;
}

.table-description{
    font-size: 22px;
    padding-bottom: 14px;
}

.table-downloadButton {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notLikeThis {
    width: 300px;
    height: auto;
}

.error-root {
    background-color: #000000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    font-size: 20px;
    text-align: center;
    padding: 100%;
    z-index: 100;

}

.errorText {
    color: #ffffff;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 30px;
}